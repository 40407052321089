
//import { useFindHeritage } from "@/network/heritageUnit";
import { openWindowWithToken } from "@/utils/util.js"
import { usePlanBackup, useQueryActionHistory } from "@/network/planBackup.ts";
import FileuploadList from "@/components/fileuploadList.vue";
import { useCityRelTown } from "@/network/chinaArea";
import { parseTime } from "@/utils/index.js";
import { dicLabel, useSelectItemList } from "@/network/lookUp.ts";
import SelectUnit from "@/views/heritageUnit/selectUnit.vue";
import roles from "@/types/roles.ts";
import { ElMessageBox } from "element-plus";
import {
  computed,
  defineComponent,
  onMounted,
  reactive,
  ref,
  toRef,
  inject,
} from "vue";
import Base from "@/views/layout/Base.vue";
import LogHistory from "@/views/shared/logHistory.vue";
import { useRouter, useRoute } from "vue-router";

export default defineComponent({
  name: "planManage",
  components: { Base, SelectUnit, FileuploadList, LogHistory },
  props: ["id", "action"],
  setup(props, { emit }) {
    const router = useRouter();
    const user: any = inject("user");
    const elForm = ref(null);
    //表单验证
    const formRules = reactive({
      bakType: [
        { required: true, message: "请选择备案类型", trigger: "change" },
      ],
      cityDept: [
        { required: true, message: "请填写市级文物行政部门", trigger: "blur" },
      ],
      doPerson: [{ required: true, message: "请填写经办人", trigger: "blur" }],
      doPersonPhone: [
        { required: true, message: "请填写经办人联系方式", trigger: "blur" },
      ],
      ownerUnitName: [
        { required: true, message: "请填写业主单位名称", trigger: "blur" },
      ],
      project_Name: [
        { required: true, message: "请填写项目名称", trigger: "blur" },
      ],
      isSplitProject: [
        {
          required: true,
          message: "请选择是否拆分多个项目实施",
          trigger: "change",
        },
      ],
      heritageUnit_Id: [
        {
          required: true,
          message: "请选择文物保护单位",
          trigger: "change",
        },
      ],
      isRevolution: [
        {
          required: true,
          message: "请选择是否革命文物",
          trigger: "change",
        },
      ],
      setupFileTitle: [
        {
          required: true,
          message: "请填写立项批复文件标题",
          trigger: "blur",
        },
      ],
      setupFileCode: [
        {
          required: true,
          message: "请填写立项批复文号",
          trigger: "blur",
        },
      ],
      setupFileDate: [
        {
          required: true,
          message: "请填写立项批复时间",
          trigger: "blur",
        },
      ],
      setupFile: [
        {
          required: true,
          trigger: "blur",
          validator: (rule, value, callback) => {
            console.log(form.attachList);
            value = form.attachList.filter((p) => p.type == "setupFile");
            if (value.length == 0) {
              return callback(new Error("请上传立项批复文件"));
            } else {
              callback();
            }
          },
        },
      ],
      planFileTitle: [
        {
          required: true,
          trigger: "blur",
          message: "请填写方案名称",
        },
      ],

      planFileDate: [
        {
          required: true,
          trigger: "blur",
          message: "请填写方案委托编制时间",
        },
      ],

      planFileUnitIsGreet: [
        {
          required: true,
          trigger: "blur",
          message: "请选择是否甲级资质单位",
        },
      ],
      planFileUnit: [
        {
          required: true,
          trigger: "blur",
          message: "请填写方案编制单位",
        },
      ],
      planFileUnitType: [
        {
          required: computed(() => form.planFileUnitIsGreet),
          trigger: "blur",
          validator: (rule, value, callback) => {
            if (form.planFileUnitIsGreet) {
              if (value === "" || value == null) {
                return callback(new Error("请填写资质类型"));
              }
            }
            callback();
          },
        },
      ],
      planFileUnitBussiness: [
        {
          required: computed(() => form.planFileUnitIsGreet),
          trigger: "blur",
          validator: (rule, value, callback) => {
            if (form.planFileUnitIsGreet) {
              if (value === "" || value == null) {
                return callback(new Error("请填写业务范围"));
              }
            }
            callback();
          },
        },
      ],
      planFileUnitWriterIsGreet: [
        {
          required: true,
          trigger: "blur",
          message: "请选择负责人是否为责任设计师",
        },
      ],
      planFileUnitWriter: [
        {
          required: true,
          trigger: "blur",
          message: "请填写方案编制人",
        },
      ],
      planFileUnitWriterExpertType: [
        {
          required: computed(() => form.planFileUnitWriterIsGreet),
          trigger: "blur",
          validator: (rule, value, callback) => {
            if (form.planFileUnitIsGreet) {
              if (value === "" || value == null) {
                return callback(new Error("请填写专业资格类型"));
              }
            }
            callback();
          },
        },
      ],
      planFileUnitWriterExpertCode: [
        {
          required: computed(() => form.planFileUnitWriterIsGreet),
          trigger: "blur",
          validator: (rule, value, callback) => {
            if (form.planFileUnitIsGreet) {
              if (value === "" || value == null) {
                return callback(new Error("请填写专业证书编号"));
              }
            }
            callback();
          },
        },
      ],
      planFileUnitWriterExpertArea: [
        {
          required: computed(() => form.planFileUnitWriterIsGreet),
          trigger: "blur",
          validator: (rule, value, callback) => {
            if (form.planFileUnitIsGreet) {
              if (value === "" || value == null) {
                return callback(new Error("请填写从业范围"));
              }
            }
            callback();
          },
        },
      ],
      planFileReviewTitle: [
        {
          required: true,
          trigger: "blur",
          message: "请填写方案批复文件标题",
        },
      ],
      planFileReviewCode: [
        {
          required: true,
          trigger: "blur",
          message: "填写方案批复文号",
        },
      ],
      planFileReviewDate: [
        {
          required: true,
          trigger: "blur",
          message: "请选择方案批复日期",
        },
      ],
      planReviewFile: [
        {
          required: true,
          trigger: "blur",
          validator: (rule, value, callback) => {
            value = form.attachList.filter((p) => p.type == "planReviewFile");

            if (value.length == 0) {
              return callback(new Error("请上传方案批复文件"));
            } else {
              callback();
            }
          },
        },
      ],
      planSuccessFile: [
        {
          required: true,
          trigger: "blur",
          validator: (rule, value, callback) => {
            value = form.attachList.filter((p) => p.type == "planSuccessFile");
            if (value.length == 0) {
              return callback(new Error("请上传工程方案"));
            } else {
              callback();
            }
          },
        },
      ],
      planEditIntroFile: [
        {
          required: true,
          trigger: "blur",
          validator: (rule, value, callback) => {
            value = form.attachList.filter(
              (p) => p.type == "planEditIntroFile"
            );
            if (value.length == 0) {
              return callback(new Error("请上传方案修改说明文件"));
            } else {
              callback();
            }
          },
        },
      ],
      // attachFile: [
      //   {
      //     required: true,
      //     trigger: "blur",
      //     validator: (rule, value, callback) => {
      //       value = form.attachList.filter((p) => p.type == "attachFile");
      //       if (value.length == 0) {
      //         return callback(new Error("请上传立项批复文件"));
      //       } else {
      //         callback();
      //       }
      //     },
      //   },
      // ],
    });
    const id: any = ref(props.id);
    console.log(id.value);
    const [isLoading, form, find, save, , review] = usePlanBackup(id);
    const [logs, queryHistory] = useQueryActionHistory();

    const isFormVaild = ref(true);

    const setupFileUploadSetting = reactive({
      limt: 1,
      typeGroup: ref({ list: form.attachList, type: "setupFile" }),
      accpet: ".pdf,.wps,.ofd,.doc,.docx",
      urlKey: "filePath",
      nameKey: "fileName",
    });

    const planReviewFileUploadSetting = reactive({
      limt: 1,
      typeGroup: ref({ list: form.attachList, type: "planReviewFile" }),
      accpet: ".pdf,.ofd",
      urlKey: "filePath",
      nameKey: "fileName",
    });

    const planSuccessFileUploadSetting = reactive({
      limt: 1,
      typeGroup: ref({ list: form.attachList, type: "planSuccessFile" }),
      accpet: ".pdf,.wps,.ofd,.doc,.docx",
      urlKey: "filePath",
      nameKey: "fileName",
    });
    const planEditIntroFileUploadSetting = reactive({
      limt: 1,
      typeGroup: ref({ list: form.attachList, type: "planEditIntroFile" }),
      accpet: ".pdf,.doc,.docx",
      urlKey: "filePath",
      nameKey: "fileName",
    });
    const fileuploadSetting = reactive({
      typeGroup: ref({ list: form.attachList, type: "attachFile" }),
      accpet: ".pdf",
      urlKey: "filePath",
      nameKey: "fileName",
    });
    const selectUnitSetting = reactive({
      visible: false,
      disabled: false,
      heritage_Name: "",
    });

    const dicBakType = useSelectItemList("PlanBakType");

    const title = computed(() => {
      return "方案备案查看";
    });
    const activeName = ref([
      "first",
      "second",
      "third",
      "fouth",
      "five",
      "six",
    ]);

    //const [, , findHeritage] = useFindHeritage();

    const currentHeritage: any = ref({});
    const selectUnitChange = (item) => {
      currentHeritage.value = item;
      //console.log(item);
      form.heritageUnit_Code = item.heritage_Code;
      form.heritageUnit_Batch = item.heritageBatch_Name;
      form.heritageUnit_Class = item.heritageClass_Name;
      form.heritageUnit_Age = item.heritageAge_Name;
      form.heritageUnit_Area = item.city_Name + " " + item.county_Name;

      //form.heritageUnit_Code = item.
      //   (form.heritageBatch_ID = item.heritageBatch_ID),
      //     (form.heritage_ManageOrg = item.heritage_ManageOrg);
      //   form.heritage_OwnerSuperior = item.heritage_OwnerSuperior;
      //   form.cityId = item.city_ID;
      //   form.countryId = item.county_ID;
    };

    const fixUploadList = () => {
      fileuploadSetting.typeGroup.list =
        planReviewFileUploadSetting.typeGroup.list =
        planSuccessFileUploadSetting.typeGroup.list =
        planEditIntroFileUploadSetting.typeGroup.list =
        setupFileUploadSetting.typeGroup.list =
          form.attachList;
    };
    onMounted(() => {
      find().then(() => {
        fixUploadList();
        selectUnitSetting.heritage_Name = form.heritageUnitName;
        // if (form.heritageUnit_Id) {
        //   findHeritage(form.heritageUnit_Id).then((res) => {
        //     currentHeritage.value = res;
        //   });
        // }
      });
      queryHistory(id.value);
      // if (props.action == "update") {
      //   isFormVaild.value = true;
      // }
    });

    const allDownload = () => {
      openWindowWithToken(`/api/Planbackup/downloadAll/${id.value}`);
    }

    const saveData = async () => {
      if (!isFormVaild.value) {
        elForm.value.validate(async (valid) => {
          if (valid) {
            //执行保存
            save();
            isFormVaild.value = true;
          } else {
            isFormVaild.value = false;
          }
        });
      } else {
        //执行保存
        await save();
      }
      fixUploadList();
      // console.log(id.value);
      if (!id.value) {
        router.push(`/ReportAndReview/Plan/Manage/${form.id}`);
        id.value = form.id;
      }
    };

    const submitFn = () => {
      elForm.value.validate((valid) => {
        if (valid) {
          //执行提交

          ElMessageBox.confirm("确认提交方案备案吗？")
            .then(() => {
              review(form.id, { result: true }).then(() => {
                router.push(`/ReportAndReview/Plan`);
              });
            })
            .catch(() => {
              console.log("cancel");
            });
        } else {
          isFormVaild.value = false;
        }
      });
    };

    const onReviewSubmit = (arg) => {
      review(form.id, arg).then(() => {
        router.push(`/ReportAndReview/Plan`);
      });
    };

    const clearValidate = (field) => {
      elForm.value.clearValidate(field);
      console.log(form.attachList);
    };

    return {
      allDownload,
      isLoading,
      user,
      roles,
      onReviewSubmit,
      logs,
      isFormVaild,
      elForm,
      formRules,
      saveData,
      submitFn,
      dicBakType,
      fileuploadSetting,
      setupFileUploadSetting,
      planReviewFileUploadSetting,
      planSuccessFileUploadSetting,
      planEditIntroFileUploadSetting,
      parseTime,
      currentHeritage,
      activeName,
      title,
      form,
      selectUnitChange,
      selectUnitSetting,
      dicLabel,
      clearValidate,
    };
  },
});
