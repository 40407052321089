import request from "@/utils/request";
import { ref, Ref, reactive, defineAsyncComponent, toRef } from "vue";
import axios from "axios";
import { ElMessage } from "element-plus";
import { copyFrom } from "./common";

export function useQuery(filter): Array<any> {
  const result = reactive({
    list: [],
    count: 0,
  });
  const isLoading = ref(false);

  const pager = reactive({
    count: toRef(result, "count"),
    pageSize: toRef(filter, "pageSize"),
    pageNum: toRef(filter, "pageNum"),
  });

  function query() {
    isLoading.value = true;
    request
      .get(`/planBackup/query`, { params: filter })
      .then((res) => {
        isLoading.value = false;
        result.list = res.list;
        result.count = res.count;
      })
      .catch(() => {
        isLoading.value = false;
      });
  }

  return [isLoading, result, query, pager];
}

export function useQueryActionHistory(): Array<any> {
  const result = ref([]);
  function query(id) {
    request.get(`/planBackup/logHistory/${id}`).then((res) => {
      result.value = res;
    });
  }
  return [result, query];
}

export function usePlanBackup(id: Ref<number> = null): Array<any> {
  const isLoading = ref(false);
  const cancel = ref(null);

  const empty = {
    id: 0,
    bakType: null,
    cityDept: null,
    doPerson: null,
    doPersonPhone: null,
    ownerUnitName: null,
    project_Code: null,
    project_Name: null,
    isSplitProject: null,
    childProject_Code: null,
    childProject_Name: null,
    heritageUnit_Id: null,
    heritageUnit_Code: null,
    heritageUnit_Batch: null,
    heritageUnit_Class: null,
    heritageUnit_Age: null,
    heritageUnit_Area: null,
    isRevolution: null,
    setupFileTitle: null,
    setupFileCode: null,
    setupFileDate: null,
    planFileTitle: null,
    planFileDate: null,
    planFileUnitIsGreet: null,
    planFileUnit: null,
    planFileUnitType: null,
    planFileUnitBussiness: null,
    planFileUnitWriterIsGreet: null,
    planFileUnitWriter: null,
    planFileUnitWriterExpertType: null,
    planFileUnitWriterExpertCode: null,
    planFileUnitWriterExpertArea: null,
    planFileReviewTitle: null,
    planFileReviewCode: null,
    planFileReviewDate: null,
    attachList: [],
    createTime: null,
    createUser: 0,
    updateTime: null,
    updateUser: null,
    status: 0,
  };

  const result = reactive(copyFrom(empty));

  function remove(idValue: number): void {
    isLoading.value = true;
    return request
      .delete(`/planBackup/${idValue}`)
      .then((res) => {
        isLoading.value = false;
        ElMessage.success({ message: "操作成功！", type: "success" });
        return res;
      })
      .catch(() => {
        isLoading.value = false;
      });
  }

  function find(idValue: number) {
    isLoading.value = true;
    const v = idValue ? idValue : id.value;
    if (v) {
      return request
        .get(`/planBackup/${v}`)
        .then((res) => {
          isLoading.value = false;
          //result.value = res;
          // console.log(
          //   Object.assign(result, copy(empty), copy(res)),
          //   copy(empty),
          //   copy(res)
          // );
          copyFrom(result, empty, res);

          return result;
        })
        .catch(() => {
          isLoading.value = false;
        });
    } else {
      isLoading.value = false;
      //result.attachList.length = 0;
      // result.attachList = [];
      copyFrom(result, empty);
      return Promise.resolve(result);
    }
  }

  function save() {
    isLoading.value = true;
    let ps = null;
    const cancelTokenSource = axios.CancelToken.source();
    cancel.value = cancelTokenSource;
    if (result.id) {
      ps = request
        .put(`/planBackup/${id.value}`, result)
        .then((res) => {
          copyFrom(result, empty, res);
          isLoading.value = false;
          ElMessage.success({ message: "数据已保存", type: "success" });
        })
        .catch((e) => {
          isLoading.value = false;
          throw e;
        });
    } else {
      ps = request
        .post(`/planBackup`, result)
        .then((res) => {
          copyFrom(result, empty, res);
          isLoading.value = false;
          ElMessage.success({ message: "数据已保存", type: "success" });
        })
        .catch((e) => {
          isLoading.value = false;
          throw e;
        });
    }

    return ps;
  }

  function review(id, reviewResult) {
    isLoading.value = true;

    return request
      .put(`/planBackup/review/${id}`, reviewResult)
      .then((res) => {
        isLoading.value = false;
        ElMessage.success({ message: "操作成功！", type: "success" });
        return res;
      })
      .catch(() => {
        isLoading.value = false;
      });
  }

  return [isLoading, result, find, save, remove, review];
}

export const usePlanBackupAction = (): Array<any> => {
  const isLoading = ref(false);
  const cancel = ref(null);

  const remove = (id) => {
    return request
      .delete(`/planBackup/${id}`)
      .then((res) => {
        isLoading.value = false;
        ElMessage.success({ message: "操作成功！", type: "success" });
        return res;
      })
      .catch(() => {
        isLoading.value = false;
      });
  };

  return [isLoading, remove];
};
