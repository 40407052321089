
import { getToken } from "@/utils/auth";
import { strMBFormat, previewOrDownload } from "@/utils/util";
import settings from "@/settings";
import { ElMessage } from "element-plus";
import { defineComponent, toRefs, ref, reactive, watch } from "vue";

export default defineComponent({
  props: {
    setting: { type: Object },
  },
  emits: ["remove", "upload", "progress"],

  setup(props, { emit, expose }) {
    const refUpload = ref(null);

    const {
      limit = ref(null),
      accpet = settings.attachment.accept,
      fileList = ref([]),
      typeGroup = ref(null),
      showTip = true,
      btnText = "点击上传",
      urlKey = "url",
      nameKey = "name",
      isFileUploading = ref(false),
    } = toRefs(props.setting);

    if (typeGroup.value != null) {
      fileList.value = typeGroup.value.list.filter(
        (p) => p.type == typeGroup.value.type
      );
      watch(
        () => typeGroup.value.list,
        () => {
          // console.log( typeGroup.value.list)
          fileList.value =
            typeGroup.value.list == null
              ? []
              : typeGroup.value.list.filter(
                  (p) => p.type == typeGroup.value.type
                );

          //console.log("fileListChanged");
        },
        { deep: true }
      );
    }

    const onExceed = () => {
      ElMessage.error(`仅允许同时上传${limit.value}份文件！`);
    };
    const onBeforeUpload = (file) => {
      if (limit.value !== null && fileList.value.length >= limit.value) {
        ElMessage.error(
          `仅允许上传${limit.value}份附件，如需修改请删除原有后重新上传！`
        );
        return false;
      }
      return true;
    };
    const onRemoveFile = (file) => {
      if (typeGroup.value != null) {
        const index = typeGroup.value.list.findIndex(
          (p) =>
            p[urlKey.value] == file[urlKey.value] &&
            p.type == typeGroup.value.type
        );
        if (index > -1) typeGroup.value.list.splice(index, 1);
      } else {
        fileList.value.splice(
          fileList.value.findIndex((p) => p == file),
          1
        );
      }
      emit("remove", file);
    };
    const onSuccess = (response, file) => {
      if (response.code == 200) {
        isFileUploading.value = false;
        file.url = response.data;
        const arg = {
          [urlKey.value]: response.data,
          [nameKey.value]: file.name,
        };
        emit("upload", arg);
        refUpload.value.clearFiles();

        //console.log(fileList.value);
        if (typeGroup.value != null) {
          typeGroup.value.list.push(
            Object.assign(arg, { type: typeGroup.value.type })
          );
          console.log(typeGroup.value.list, "uploadedPush");
        } else {
          fileList.value.push(arg);
        }
      }
    };
    const onProgress = () => {
      isFileUploading.value = true;
      emit("progress");
    };

    expose({
      clear: () => {
        fileList.value = [];
      },
    });

    return {
      btnText,
      typeGroup,
      action: settings.uploadAction,
      showTip,
      refUpload,
      limit,
      accpet,
      onBeforeUpload,
      onExceed,
      onRemoveFile,

      onSuccess,
      fileList,
      onProgress,
      urlKey,
      nameKey,
    };
  },
});
