import { reactive, ref, Ref, toRef, watch } from "vue";
import request from "@/utils/request";

const cache = {};

export const dicLabel = (value, dic) => {
  if (typeof value != "object") return dic.find((p) => p.value == value)?.label;
  else {
    return dic
      .filter((p) => value?.some((d) => d == p.value))
      .map((p) => p.label)
      .join(" ");
  }
};

export const attachSetting = [
  {
    id: 1,
    required: true,
    label: "申请文件",
    ext: ".WPS,. OFD,.PDF,.DOC,.DOCX",
    tip: "支持扩展名：WPS / OFD / PDF / DOC / DOCX，请上传市级人民政府的征求意见文件，内容包括：建设单位名称、建设项目、建设地点、建设规模、必须进行该工程的理由说明（文件大小不超过100MB）",
    limit: 1,
  },
  {
    id: 2,
    required: true,
    label: "建设工程的规划、设计方案及保护措施方案",
    tip: "支持扩展名：WPS / OFD / PDF / DOC / DOCX，请上传建设工程的规划、设计方案，内容包括：1/500或者1/2000现状地形图（标出涉及的文物保护单位保护范围），建设工程设计方案还需上报相关建筑的总平面图、平面、立面、剖面图（文件大小不超过100MB）",
    ext: ".WPS,.OFD,.PDF,.DOC,.DOCX",
  },
  {
    id: 3,
    required: true,
    label: "文物影响评估报告",
    ext: ".WPS,. OFD,.PDF,.DOC,.DOCX",
    tip: "支持扩展名：wps / ofd / pdf / doc / docx（文件大小不超过100MB）",
    limit: 1,
  },
  {
    id: 4,
    required: true,
    label: "保护区划及管理规定",
    ext: ".WPS,. OFD,.PDF,.DOC,.DOCX",
    tip: "支持扩展名：wps / ofd / pdf / doc / docx，请上传现行的法定保护区划，在区划图中明确标识项目范围（文件大小不超过100MB）",
    limit: 1,
  },
  {
    id: 5,
    required: false,
    label: "考古勘探或发掘报告",
    ext: ".WPS,. OFD,.PDF,.DOC,.DOCX",
    tip: "支持扩展名：wps / ofd / pdf / doc / docx，涉及地下埋藏文物的，须提供考古勘探发掘资料（文件大小不超过100MB）",
    limit: 1,
  },
  {
    id: 6,
    required: false,
    label: "其他",
    ext: ".WPS,. OFD,.PDF,.DOC,.DOCX",
    tip: "支持扩展名：wps / ofd / pdf / doc / docx（文件大小不超过100MB）",
  },
  {
    id: 7,
    required: true,
    label: "迁移或拆除文物保护单位方案及相关假设工程规划设计方案",
    ext: ".WPS,. OFD,.PDF,.DOC,.DOCX",
    limit: 1,
  },
  {
    id: 8,
    required: true,
    label: "保护工程方案",
    ext: ".WPS,. OFD,.PDF,.DOC,.DOCX",
    limit: 1,
  },
  {
    id: 9,
    required: false,
    label: "立项批复文件",
    ext: ".WPS,. OFD,.PDF,.DOC,.DOCX",
    limit: 1,
  },
  {
    id: 10,
    required: true,
    label: "安全防护工程方案",
    ext: ".WPS,. OFD,.PDF,.DOC,.DOCX",
    limit: 1,
  },
  {
    id: 11,
    required: false,
    label: "保护规划",
    ext: ".WPS,. OFD,.PDF,.DOC,.DOCX",
    tip: "支持扩展名：wps / ofd / pdf / doc / docx，请上传全国重点文物保护规划方案及图纸（文件大小不超过100MB）",
    limit: 1,
  },
  {
    id: 12,
    required: true,
    label: "市县级文物行政部门意见",
    ext: ".WPS,. OFD,.PDF,.DOC,.DOCX",
    limit: 1,
  },
];

export const things = [
  {
    code: "56004",
    thingsName: "全国重点文物保护单位原址保护措施审批",
    org: "国家文物局",
    timelimit: "20个工作日(委托评估、专家评审时间除外)",
    applyUnit: "项目建设单位",
    applyType: null,
    applyTypeName: null,
  },
  {
    code: "56005",
    thingsName:
      "省级和全国重点文物保护单位保护范围内其他建设工程或者爆破、钻探、挖掘等作业审批申请表",
    org: "国家文物局",
    timelimit: "20个工作日(委托评估、专家评审时间除外)",
    applyUnit: "项目所在地省级人民政府",
    applyType: null,
    applyTypeName: null,
  },
  // {
  //   code: "56006",
  //   thingsName: "省级文物保护单位的迁移或拆除审批",
  //   org: "国家文物局",
  //   timelimit: "20个工作日(委托评估、专家评审时间除外)",
  //   applyUnit: "项目所在地省级政府",
  //   applyType: null,
  //   applyTypeName: null,
  // },
  {
    code: "56010",
    thingsName: "省级文物保护单位的迁移或拆除审批",
    org: "国家文物局",
    timelimit: "20个工作日(委托评估、专家评审时间除外)",
    applyUnit: "项目所在地省级政府",
    applyType: null,
    applyTypeName: null,
  },
  {
    code: "56012",
    thingsName: "全国重点文物保护单位建设控制地带内建设工程设计方案审批",
    org: "国家文物局",
    timelimit: "20个工作日(委托评估、专家评审时间除外)",
    applyUnit: "项目建设单位",
    applyType: null,
    applyTypeName: null,
  },
  {
    code: "56014",
    rowSpan: 2,
    thingsName: "全国重点文物保护单位修缮审批",
    org: "国家文物局或者文物局委托的省级文物行政部门",
    timelimit: "20个工作日(委托评估、专家评审时间除外)",
    applyUnit: "全国重点文物保护单位管理机构或者所有人",
    applyType: 1,

    applyTypeName: "全国重点文物保护单位修缮项目审批",
  },
  {
    code: "56014",
    rowSpan: 0,
    thingsName: "全国重点文物保护单位修缮审批",
    org: "国家文物局或者文物局委托的省级文物行政部门",
    timelimit: "20个工作日(委托评估、专家评审时间除外)",
    applyUnit: "全国重点文物保护单位管理机构或者产权单位",
    applyType: 2,
    applyTypeName: "全国重点文物保护单位安全防护工程项目审批",
  },
  {
    code: "56022",
    thingsName: "全国重点文物保护单位、省级文物保护单位保护规划审批",
    org: null,
    timelimit: null,
    applyUnit: null,
    applyType: null,
  },
];

export const docOrgDic = [
  { label: "国家文物局", value: "1" },
  { label: "省文物局", value: "2" },
  { label: "市文物局", value: "3" },
  { label: "县文物局", value: "4" },
];

export const docStatusDic = [
  { label: "退回", value: 0 },
  { label: "待审核", value: 1 },
  { label: "已审核", value: 2 },
];

export const scheduleTypeDic = [
  {
    label: "修缮工程",
    value: "7",
    ext: { projectType: 0 },
  },
  {
    label: "保护展示工程",
    value: "2",
    ext: { projectType: 0 },
  },
  {
    label: "迁建工程",
    value: "11",
    ext: { projectType: 0 },
  },
  {
    label: "环境整治",
    value: "12",
    ext: { projectType: 0 },
  },
  // {
  //   label: "保护性设施建设工程",
  //   value: "3",
  //   ext: { projectType: 0 },
  // },
  {
    label: "其他保护性设施建设工程",
    value: "4",
    ext: { projectType: 0 },
  },
  // {
  //   label: "其他",
  //   value: "6",
  //   ext: { projectType: 0 },
  // },
  {
    label: "保护规划编制",
    value: "1",
    ext: { projectType: 2 },
  },
  {
    label: "保护工程实施",
    value: "5",
    ext: { projectType: 2 },
  },
  {
    label: "保护区划",
    value: "8",
    ext: { projectType: 2 },
  },
  {
    label: "科学研究",
    value: "9",
    ext: { projectType: 2 },
  },
  {
    label: "培训教学",
    value: "10",
    ext: { projectType: 2 },
  },
  { label: "安防", value: "11", ext: { projectType: 1 } },
  { label: "消防", value: "12", ext: { projectType: 1 } },
  { label: "防雷", value: "13", ext: { projectType: 1 } },
];

export const setupProjectYearBatch = ref([]);
const now = new Date();
for (let i = 0; i < 10; i++) {
  setupProjectYearBatch.value.push({
    yearBatch: (now.getFullYear() - i).toString(),
  });
}

setupProjectYearBatch.value[0].isActive = true;
// [
//   {
//     yearBatch: "2022",
//   },
//   {
//     yearBatch: "2023",
//     isActive: true,
//   },
// ]

export const planBackupStatusText = ["草稿", "市级审核", "省级审核", "已备案"];
export const thingApplyStatusText = [
  "草稿",
  "市级审核",
  "省级受理中",
  "省级已办结",
];
export const setupProjectStatusText = ["草稿", "市级审核", "市级已审核","省级已批复","已备案"];
export const setupProjectFileSignStatusText = [
  "",
  "市级编审",
  "省级审核",
  "已完结",
];

// export const fundWatchProjectDoStatusDic = [
//   "前期准备",
//   "工程未启动",
//   "工程进行中",
//   "工程完工",
//   "竣工验收",
// ];

export const fundWatchProjectDoStatusDic = [
  "前期准备",
  "正在施工",
  "已完工",
  "竣工验收",
];

// export const fundWatchDoStatusDic = [
//   "已完成财务验收",
//   "已完工未结清",
//   "已完工未支出",
//   "已完工已结清",
//   "已支出合同首期",
//   "已支出前期费用",
//   "资金未到位",
//   "资金已到位",
//   "资金已收回",
// ];

export const fundWatchDoStatusDic = [
  "未到位",
  "已到位未支出",
  "已支出未结清",
  "已结清",
];

export const fundWatchSourceDic = ["省级财政", "中央财政"];

export const useSelectItemList = (looktypeid, forceRefresh = false) => {
  if (!forceRefresh && cache[looktypeid]) return cache[looktypeid];

  cache[looktypeid] = ref([]);
  request(`Infrastructure/Lookups/${looktypeid}`).then((list) => {
    cache[looktypeid].value = list;
  });

  return cache[looktypeid];
};

export const useSelectItemWithChildList = (
  parentRefValue,
  childRefValue,
  looktypeid,
  forceRefresh = false
) => {
  const child = ref([]);
  const list = useSelectItemList(looktypeid, forceRefresh);
  watch(parentRefValue, (v, o) => {
    if (v != null) {
      child.value = list.value.find((p) => p.value == v)?.children;
      if (!child.value?.some((p) => p.value == childRefValue.value)) {
        childRefValue.value = null;
      }
    }
  });
  return [list, child];
};
